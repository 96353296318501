import "./App.css";
import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
window.Buffer = Buffer;

const CLIENT_ID =
  "634005505567-b1mbnlu6tnbd0njd8a1t4gmj9narfapu.apps.googleusercontent.com";
const ENDPOINT = "https://wax.greymass.com";
let API_URL = "https://secret-share-sign-server.digitechsolutions.co.in";

//Localhost settings - Move to env..Just for quick test
if (window.location.hostname === "localhost") {
  API_URL = "http://localhost:8080";
}


function App() {
  const [state, setState] = useState(null);
  const [formData, setFormData] = useState({ client_share: null });
  const [transactResponse, setTransactResponse] = useState(null);
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleCredentialResponse,
    });
  }, []);
  const handleCredentialResponse = (response) => {
    //console.log(response);
    fetch(`${API_URL}/google/token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ credential: response.credential }),
    })
      .then((response) => response.json())
      .then((data) => setState(data));
  };

  const onGoogleLogin = () => {
    window.google.accounts.id.prompt();
  };

  const transact = async () => {
    try {
      const secretShares = [formData.client_share, state.server_share];

      const secretHex = window.secrets.combine(secretShares);
      const privateKey = window.secrets.hex2str(secretHex);

      const signatureProvider = new JsSignatureProvider([privateKey]);
      const rpc = new JsonRpc(ENDPOINT); //required to read blockchain state
      const api = new Api({ rpc, signatureProvider }); //required to submit transactions

      var actions = [];

      actions.push({
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor: state.actor,
            permission: state.permission,
          },
        ],
        data: {
          from: state.actor,
          to: "fpkqw.waa",
          quantity: `0.00001000 WAX`,
          memo: "",
        },
      });

      const response = await api.transact(
        {
          actions: actions,
        },
        {
          broadcast: true,
          blocksBehind: 3,
          expireSeconds: 60 * 3,
        }
      );
      console.log(response);
      setTransactResponse(response);
    } catch (err) {
      setTransactResponse(err);
    }
  };
  return (
    <div className="App" style={{ padding: "10px" }}>
      {!state ? (
        <>
          <button onClick={onGoogleLogin}>Login Via Google</button>
        </>
      ) : (
        <>
          <h4>
            Logged in: {state.payload.name} ({state.payload.email})
          </h4>
          <h4>
            Account: {state.actor}@{state.permission}
          </h4>

          <lable>Client Share: </lable>
          <br />
          <textarea
            type="text"
            name="client_share"
            onChange={(e) =>
              setFormData({ ...formData, client_share: e.target.value })
            }
            style={{width:'80%',height:'200px'}}
            //rows={10}
            //cols={100}
          >
            {formData.client_share}
          </textarea>
          <br />
          <button onClick={transact}>Transact</button>
          <br />
          {transactResponse ? JSON.stringify(transactResponse) : null}
        </>
      )}
    </div>
  );
}

export default App;
